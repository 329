$subaru-blue: #2254C4
$subaru-white: #C4DEFF
$subaru-yellow: #F8F338
//$textColor: scale-color(rgba($subaru-blue, 1), $lightness: -60%)
$textColor: black
$thresholdWidth: 800px
$minimum-width: 360px

%element-shadow
  box-shadow: 0px 0px 8px 8px rgba(desaturate($subaru-blue, 50), 1)

%main-text-shadow
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4)

%footer
  z-index: 1000
  display: flex
  padding: 0 5em
  align-items: center
  justify-content: center
  bottom: 0
  width: 100%
  background-color: scale-color(rgba($subaru-blue, 1), $lightness: -60%)
  text-align: center
  color: lightgray
  font-size: smaller
  min-width: $minimum-width

  > div
    margin: 1em

  .footer-address
    max-width: 40%
    text-align: left

  .footer-social
    white-space: nowrap

    a
      margin-right: 0.5em

  .footer-phones
    text-align: left
    font-weight: bold

@keyframes opacity-animate
  0%
    opacity: 0
  100%
    opacity: 0.3

body
  background-color: white
  overflow-x: hidden
  min-width: $minimum-width

.wallpaper
  animation: opacity-animate normal 3s ease
  position: fixed
  width: 100vw
  height: 100vh
  opacity: 0.3
  bottom: 0
  background-repeat: no-repeat
  background-size: cover

.header
  display: flex
  position: relative
  justify-content: center
  background-color: $subaru-blue
  z-index: 2

  .header-logo
    background-size: cover
    background-position: 50% 50%
    width: 100%
    height: 340px
    opacity: 0.6

  .header-logo-555
    position: absolute
    height: 200px
    top: 70px
    filter: drop-shadow(4px 4px 8px scale-color(rgba($subaru-blue, 1), $lightness: -60%))

.footer-sticky
  position: fixed
  @extend %footer

.footer
  position: relative
  @extend %footer

@media only screen and (max-width: $thresholdWidth)
  .footer-sticky
    //position: relative
    flex-direction: column

    > div
      margin: 0.5em

    .footer-address
      max-width: none

.dummy-vertical-spacer
  height: 3em

.navbar
  //border-top: 2px solid $subaru-yellow
  box-shadow: 0 10px 8px 0 rgba($subaru-blue, 0.4)
  margin: 0
  padding: 0
  width: 100%
  background-color: $subaru-blue

  .container
    display: flex
    justify-content: center
    padding-top: 0.5em
    padding-bottom: 0.5em

    .nav-link
      color: $subaru-yellow !important
      text-shadow: 0 0 15px #FFFFFF

  .active
    border-color: white

    .nav-link
      font-weight: bold

    .nav-link:hover
      color: $subaru-yellow !important

  .nav-link:hover
    color: orange !important

.main-body
  max-width: 1080px
  position: relative
  opacity: 1
  margin-top: 2em
  margin-left: auto
  margin-right: auto
  color: $textColor
  @extend %main-text-shadow

  .body-paragraph
    width: 75%
    max-width: 1280px
    margin: 1em auto

.main-header
  width: 75%
  margin: 0 auto
  text-align: center
  font-size: larger
  font-weight: bold
  @extend %main-text-shadow

.prices
  position: relative

  table
    margin: 2em auto
    @extend %main-text-shadow

    thead
      font-weight: bold
      white-space: nowrap
      background-color: rgba($subaru-blue, 0.2)

      th
        padding: 1em

    tbody
      tr
        border-bottom: 1px solid $subaru-blue

    .tr-has-value:hover
      background-color: rgba($subaru-yellow, 0.3)

    .td-name
      padding-right: 2em

    .td-value
      white-space: nowrap
      padding-left: 2em

    .td-sub
      margin-left: 3em
      font-style: italic

.navbar-expand-lg .navbar-nav .nav-link
  padding-left: 0.5rem !important
  padding-right: 0.5rem !important

@media only screen and (max-width: 520px)
  .prices
    font-size: smaller

@media only screen and (max-width: 420px)
  .prices
    font-size: x-small

@media only screen and (max-width: $thresholdWidth)
  .navbar
    .container
      padding-top: 0 !important
      padding-bottom: 0 !important
  .header
    .header-logo
      height: 8em
    .header-logo-555
      height: 4em
      top: 2em
  .prices
    table
      margin: 2em 2em
  .footer
    flex-direction: column
    padding: 0
    .footer-address
      max-width: unset
      text-align: center

$howToFindImageWidth: 800px

.contacts
  .image-gallery
    max-width: $howToFindImageWidth
    margin: 2em auto
    @extend %element-shadow

.how-to-find-album-class
  max-width: $howToFindImageWidth
  margin-right: 1em

.leaflet-container
  max-width: $howToFindImageWidth
  width: 80vw
  height: 35vh
  margin: 2em auto
  @extend %element-shadow

.image-gallery-slide
  .image-gallery-image
    object-fit: cover !important

